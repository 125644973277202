export const environment = {
    production: true,
    apiUrl: 'https://pim.orderfant.app/api',
    apiUrlPilot: 'https://siwaorderpim.pilot01.siwa.at/api',
    clientId: 10,
    stripe: {
        pk: 'pk_test_51GwiejBm3Ogsls4joyCrTAleMSuUy2XgVdNIKW0rafgjIb7jTLdyJs8iovRArAVezGtBRvJyRpwQF8xoXH2YQg6U00XLQvbu9S'
    },
    desktop: true
};
